<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/stores/app'

const appStore = useAppStore()
const { isMdAndUp } = storeToRefs(appStore)
const router = useRouter()
const route = useRoute()

const invalidPaths = ['qn', 'new']

const crumbItems = computed(() => {
  const fullPath = route.fullPath
  const params = fullPath.startsWith('/') ? fullPath.substring(1).split('/') : fullPath.split('/')
  const crumbs: any = []
  let path = ''
  //   console.log(`\nfullPath = ${fullPath}, params = ${JSON.stringify(params)}`)

  params.forEach((param, index) => {
    path = `${path}/${param}`
    const match = router.resolve(path)
    //  console.log(`match = ${JSON.stringify(match)}`)

    if (match.name !== null) {
      const title = param.replace(/-/g, ' ')
      crumbs.push({
        title: title,
        ...match,
      })
    }
  })
  //   console.log(`crumbs = ${JSON.stringify(crumbs)},
  //   route = ${JSON.stringify(route)}`)

  return crumbs
})
</script>

<template>
  <div>
    <ul v-if="isMdAndUp && route.path !== '/'" class="v-breadcrumbs v-breadcrumbs--density-default breadcrumbs">
      <li class="v-breadcrumbs-divider text-white">/</li>
    </ul>

    <v-breadcrumbs v-if="isMdAndUp" :items="crumbItems" divider="/" class="text-white breadcrumbs">
      <template #item="{ item }">
        <v-btn variant="plain" size="large" :to="item.href" nuxt :disabled="item.href === route.path" class="px-0 toolbar_btn breadcrumbs-btn">{{ item.title.toUpperCase() }}</v-btn>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<style scoped lang="scss">
/* Breadcrumbs */
ul.breadcrumbs {
  padding-left: 0;
}
.breadcrumbs {
  display: inline-flex;
  padding: 0 !important;
}
.breadcrumbs-btn {
  opacity: 0.6 !important;
  min-width: auto !important;
}
.breadcrumbs-btn:hover {
  opacity: 1 !important;
}
.breadcrumbs-btn-disabled {
  opacity: 1 !important;
  pointer-events: none !important;
  cursor: default;
}
.breadcrumb-divider {
  font-size: 16px !important;
  opacity: 0.6 !important;
}
</style>
