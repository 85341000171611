<script setup>
import { storeToRefs } from 'pinia'
import { useUserStore } from '~/stores/user'
import { mergeProps } from 'vue'
import { useAppStore } from '~/stores/app'
import { useAuthStore } from '~/stores/auth'

const appStore = useAppStore()
const { profileMenu } = storeToRefs(appStore)

const authStore = useAuthStore()
const { logout } = authStore
const { isPending } = storeToRefs(authStore)

const userStore = useUserStore()
const { user, fullName, title } = storeToRefs(userStore)

function closeMenu() {
  profileMenu.value = false
}

function initialize() {
  //   console.log(`TheProfileMenu(): user = ${JSON.stringify(user.value)}`)
}

function logoutUser() {
  console.log(`logoutUser()`)

  logout()
}

initialize()
</script>

<template>
  <v-menu v-model="profileMenu" :close-on-content-click="false" location="bottom">
    <template #activator="{ props: menu }">
      <v-tooltip location="bottom">
        <template #activator="{ props: tooltip }">
          <v-btn icon color="white" v-bind="mergeProps(menu, tooltip)">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <span>Profile</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-list>
        <v-list-item prepend-icon="mdi-account-circle" :title="fullName" />
      </v-list>

      <v-divider />

      <v-container class="bg-grey-lighten-4">
        <v-row dense>
          <v-col cols="6" md="5"> Role: </v-col>
          <v-col cols="6" md="7"> {{ title }} </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="5"> Chapter: </v-col>
          <v-col cols="6" md="7"> {{ user.chapter[0].long_name }} </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <v-card-actions class="d-flex justify-space-between">
        <v-btn variant="outlined" color="secondary" :loading="isPending" @click="logoutUser"> Logout </v-btn>
        <v-btn variant="outlined" color="secondary" :to="`/users/${user.id}/edit`" nuxt @click="closeMenu"> Edit </v-btn>
        <v-btn variant="text" @click="closeMenu"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
